import PropTypes from 'prop-types'
import React from 'react'
import CookieAgreement from './CookieAgreement'
import Footer from './Footer'
import Hero from './Hero'
import Navbar from './Navbar'
import SEO from './SEO'

const Layout = ({
  children,
  header,
  featuredMedia,
  seo,
  className,
  lightText,
  featuredMediaUsesGatsbyImage = true,
  navbarContainerStyles = {},
}) => {
  return (
    <React.Fragment>
      <SEO {...seo} />
      <Navbar containerStyles={navbarContainerStyles} lightText={lightText} />
      {header && featuredMediaUsesGatsbyImage ? (
        <Hero
          featuredMedia={featuredMedia}
          className={className}
          title={header.title}
          subtitle={header.subtitle}
        />
      ) : header ? (
        <Hero
          gatsbyImage={false}
          featuredMedia={featuredMedia}
          className={className}
          title={header.title}
          subtitle={header.subtitle}
        />
      ) : null}
      {children}
      <Footer />
      <CookieAgreement />
    </React.Fragment>
  )
}

Layout.propTypes = {
  lightText: PropTypes.bool,
  className: PropTypes.string,
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  seo: PropTypes.shape({
    isBlogPost: PropTypes.bool,
    isLandingPage: PropTypes.bool,
    postData: PropTypes.shape({
      acf: PropTypes.object,
      author: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        path: PropTypes.string,
      }),
      categories: PropTypes.array,
      content: PropTypes.string,
      date: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      tags: PropTypes.array,
      title: PropTypes.string,
    }),
    postImage: PropTypes.string,
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }),
}

export default Layout
